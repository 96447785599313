.App {
  text-align: center;
}

.App-logo {
  float: left;
  max-height: 4vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-toolbar {
  background-color: white;
  color: #282c34;
}

.App-title {
  text-align: center;
}

.App-header {
  background-color: white;
  padding-top: 250px;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  margin-left: 5%;
}

.App-link {
  color: #61dafb;
}